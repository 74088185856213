<template>
	<div class="row cc right" v-show="pageShow">
		<el-popover v-model="showPop" placement="bottom" width="320" trigger="click">
			<div class="row cc" slot="reference">
				<el-link :underline="false">登录</el-link>
				<el-link :underline="false">/</el-link>
				<el-link :underline="false">注册</el-link>
			</div>
			<div>
				<div class="col login-view qrcode-view a" v-show="loginType == 'qrcode'">
					<div class="row cc login-tips">
						<div class="row cc tag">扫码</div>
						<div class="row cc title">首次登录，奖励200积分</div>
					</div>
					<el-image :src="qr.qrcode" class="qrcode"></el-image>

					<div class="row cc wehchat-tips">
						<el-image :src="require('@/pc/assets/login/wechat.png')" class="wechat"></el-image>
						<div class="row cc text">打开微信，扫一扫登录</div>
					</div>
					<div class="row cc mobile" @click="changeType('mobile')">手机号验证码登录</div>
				</div>
				<div class="col login-view mobile-view a" v-show="loginType == 'mobile'">
					<div class="row cc login-tips">
						<div class="row cc tag">扫码</div>
						<div class="row cc title">首次登录，奖励200积分</div>
					</div>
					<el-form :model="loginForm" class="form" :rules="rules" ref="ruleForm">
						<el-form-item prop="mobile">
							<el-input maxlength="11" v-model="loginForm.mobile" placeholder="请输入手机号">
								<template slot="prepend">
									<el-dropdown trigger="click">
										<span class="el-dropdown-link">
											+86
											<i class="el-icon-caret-bottom"></i>
										</span>
										<el-dropdown-menu slot="dropdown"><el-dropdown-item>+86</el-dropdown-item></el-dropdown-menu>
									</el-dropdown>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="smscode">
							<el-input maxlength="6" v-model="loginForm.smscode" placeholder="6位数字验证码">
								<template slot="append">
									<el-button @click="sendSms()" :loading="smsLoading" :class="{ 'sms-dis': smsTimeOut < 60 }">
										{{ smsTimeOut < 60 ? smsTimeOut + '秒后重发' : '获取验证码' }}
									</el-button>
								</template>
							</el-input>
						</el-form-item>
					</el-form>
					<el-button class="btn" type="primary" :loading="loginLoading" @click="onLogin">登录/注册</el-button>

					<div class="col cc wehchat-tips" @click="changeType('qrcode')">
						<div class="row cc text">微信扫码登录</div>
						<el-image :src="require('@/pc/assets/login/wechat1.png')" class="wechat"></el-image>
					</div>
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	inject: ['initSuccess'],
	data() {
		return {
			showPop: false,
			loginType: 'mobile',
			code: '+86',
			loginForm: {
				mobile: '',
				smscode: ''
			},
			smsIntval: '',
			smsLoading: false,
			smsTimeOut: 60,
			rules: {
				mobile: [{ required: true, message: '手机号不能为空', trigger: 'blur' }, { validator: this.$validate.checkPhone, trigger: 'blur' }],
				smscode: [
					{ required: true, message: '验证码不能为空', trigger: 'blur' },
					{
						validator: (rule, value, callback) => {
							if (value.length == 6) {
								callback();
							} else {
								return callback(new Error('请输入6位数字验证码'));
							}
						},
						trigger: 'blur'
					}
				]
			},
			loginLoading: false,
			qrIntval: '',
			qr: { qrcode: '', id: '' },
			qrCheckIntval: ''
		};
	},
	computed: {
		...mapState(['token', 'loginDialog', 'tokenExpired']),
		pageShow() {
			return !this.token || this.tokenExpired;
		}
	},
	watch: {
		loginDialog() {
			if (this.loginDialog) {
				this.showPop = true;
			} else {
				this.showPop = false;
			}
		},
		showPop() {
			if (this.showPop) {
				this.getLoginCode();
				// 十分钟自动刷新登录二维码
				this.qrIntval = setInterval(() => {
					this.getLoginCode();
				}, 1000 * 60 * 10);
			} else {
				clearInterval(this.qrIntval);
				clearInterval(this.qrCheckIntval);
			}
		}
	},
	created() {
		this.initSuccess();
	},
	methods: {
		getLoginCode() {
			this.$service.post('auth/loginQrcode').then(res => {
				this.qr = res.data;
				// 查询扫码登录结果
				this.qrCheckIntval = setInterval(() => {
					this.$service.post('auth/getLoginResult', { id: this.qr.id }).then(res => {
						if (res.data.status == 1) {
							clearInterval(this.qrCheckIntval);
							this.loginSuccess(res);
						}
					});
				}, 1000);
			});
		},
		sendSms() {
			if (this.smsIntval) return;
			this.smsLoading = true;
			const reg = /1[3456789]{1}\d{9}$/;
			if (reg.test(this.loginForm.mobile)) {
				this.smsTimeOut--;
				this.$service
					.post('user_info/getsmsCode', {
						mobile: this.loginForm.mobile
					})
					.then(() => {
						this.smsIntval = setInterval(() => {
							if (this.smsTimeOut == 0) {
								clearInterval(this.smsIntval);
								this.smsIntval = '';
								this.smsTimeOut = 60;
							}
							this.smsTimeOut--;
						}, 1000);
					})
					.catch(() => {
						clearInterval(this.smsIntval);
						this.smsIntval = '';
						this.smsTimeOut = 60;
					})
					.finally(() => {
						this.smsLoading = false;
					});
			} else {
				this.smsLoading = false;
				this.$message({
					type: 'error',
					message: '请输入正确的手机号码'
				});
			}
		},
		onLogin() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					this.loginLoading = true;
					this.$service
						.post('auth/login', this.loginForm)
						.then(res => {
							this.loginSuccess(res);
						})
						.finally(() => {
							this.loginLoading = false;
						});
				} else {
					return false;
				}
			});
		},
		loginSuccess(res) {
			this.$store.dispatch('setToken', res.data.token);
			this.$store.dispatch('setUserId', res.data.user_id);
			this.$message({
				type: 'success',
				message: '登录成功'
			});
			setTimeout(() => {
				window.location.reload();
			}, 800);
		},
		changeType(val) {
			this.loginType = val;
		}
	}
};
</script>

<style scoped lang="less">
.right {
	user-select: none;
	flex-shrink: 0;
	/deep/.el-link--inner {
		color: #999999;
		font-size: 16px;
		user-select: none;
		font-weight: normal;
	}
}
.el-button--info {
	color: #999999;
	background-color: #f2f2f2;
	border-color: #f2f2f2;
}
.login-view {
	width: 320px;
	height: 340px;
	background: #ffffff;
	box-shadow: 0px 0px 4px 1px rgba(236, 236, 236, 1);
	border-radius: 4px;
	padding: 28px;
	box-sizing: border-box;
	align-items: center;
	justify-content: space-between;
	.login-tips {
		.tag {
			width: 43px;
			height: 24px;
			background: #e89e42;
			border-radius: 2px;
			color: #ffffff;
			font-size: 12px;
		}
		.title {
			color: #e89e42;
			font-size: 16px;
			margin-left: 8px;
			font-weight: bold;
		}
	}
}
.qrcode-view {
	.qrcode {
		width: 138px;
		height: 138px;
	}
	.wehchat-tips {
		.wechat {
			width: 18px;
			height: 18px;
		}
		.text {
			margin-left: 8px;
			color: #999999;
			font-size: 14px;
		}
	}
	.mobile {
		font-size: 16px;
		color: @green;
		font-weight: bold;
	}
}
.mobile-view {
	justify-content: center;
	.form {
		margin-top: 24px;
		width: 100%;
		/deep/ .el-input-group__append {
			background-color: transparent;
			button {
				padding: 0 10px;
				color: #333333;
			}
		}
		/deep/ .el-input-group__prepend {
			background-color: transparent;
			padding: 0 10px;
		}
		.sms-dis {
			color: #999999 !important;
		}
	}
	.btn {
		width: 100%;
	}
	.wehchat-tips {
		margin-top: 20px;
		.text {
			color: #999999;
			font-size: 12px;
		}
		.wechat {
			margin-top: 6px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}
	}
}
</style>

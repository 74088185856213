<template>
	<div class="row cc user-box" v-show="pageShow">
		<el-popover placement="bottom" width="300" trigger="click">
			<div>
				<el-tabs v-model="activeName">
					<el-tab-pane :label="`消息（${total.msg || 0}）`" name="first" :disabled="msg.loading">
						<div class="msg-list">
							<template v-for="(item, index) in msg.data">
								<div class="row msg-item a" v-if="item.type == 2" @click="navMsg(index)">
									<div class="avatar-box">
										<el-image :src="item.data.avatar" class="avatar"></el-image>
										<div class="dot" v-if="item.is_read == 0"></div>
									</div>
									<div class="col msg-main">
										<div class="title text-ellipsis">
											<span>{{ item.data.nickname }}</span>
										</div>
										<div class="content text-ellipsis-2">{{ item.data.describe }}</div>
										<div class="time">{{ item.create_time_text }}</div>
									</div>
								</div>
								<div
									class="row msg-item a"
									v-else-if="item.type == 3 || item.type == 4 || item.type == 5 || item.type == 7 || item.type == 8 || item.type == 14"
									@click="navMsg(index)"
								>
									<div class="avatar-box">
										<el-image :src="item.data.avatar" class="avatar"></el-image>
										<div class="dot" v-if="item.is_read == 0"></div>
									</div>
									<div class="col msg-main">
										<div class="title text-ellipsis">
											<span>{{ item.data.nickname }}</span>
										</div>
										<div class="content text-ellipsis-2">{{ item.data.describe }}</div>
										<div class="time">{{ item.create_time_text }}</div>
									</div>
								</div>
							</template>
							<div class="row cc more-text a" v-show="msg.loading && !msg.finish">加载中……</div>
							<div class="row cc more-text a" v-show="!msg.loading && !msg.finish" @click="loadMsg()">点击查看更多</div>
							<div class="row cc more-text a" v-show="msg.finish">没有更多消息了~</div>
							<el-empty description="暂未收到消息" v-show="!msg.loading && msg.finish && msg.data.length == 0"></el-empty>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="`通知（${total.notice || 0}）`" name="second" :disabled="msg.loading">
						<div class="msg-list">
							<template v-for="(item, index) in msg.data">
								<div class="row msg-item a" @click="navMsg(index)">
									<div class="avatar-box">
										<el-image :src="item.data.icon" class="avatar"></el-image>
										<div class="dot" v-if="item.is_read == 0"></div>
									</div>
									<div class="col msg-main">
										<div class="title text-ellipsis">
											<span>{{ item.data.describe }}</span>
										</div>
										<div class="time">{{ item.create_time_text }}</div>
									</div>
								</div>
							</template>
							<div class="row cc more-text a" v-show="msg.loading && !msg.finish">加载中……</div>
							<div class="row cc more-text a" v-show="!msg.loading && !msg.finish" @click="loadMsg()">点击查看更多</div>
							<div class="row cc more-text a" v-show="msg.finish">没有更多通知了~</div>
							<el-empty description="暂未收到通知" v-show="!msg.loading && msg.finish && msg.data.length == 0"></el-empty>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="row msg-view a" slot="reference">
				<div class="row img-view">
					<el-image :src="require('@/pc/assets/login/msg.png')" class="msg"></el-image>
					<div class="row cc num" v-if="userInfo.unread_num">{{ userInfo.unread_num }}</div>
				</div>
				<div class="title">消息</div>
			</div>
		</el-popover>
		<el-popover v-model="popUser" placement="bottom" width="130" trigger="click">
			<div class="row cc user-info a" slot="reference">
				<template v-if="userInfo.id">
					<el-image :src="userInfo.avatar" class="avatar"></el-image>
					<div class="nickname b">{{ userInfo.nickname }}</div>
				</template>
			</div>
			<div class="user-info-popover">
				<div class="row item a" @click="navUserHome()">
					<el-image :src="require('@/pc/assets/login/icon-1.png')" class="img"></el-image>
					<div class="name">个人中心</div>
				</div>
				<div class="row item a" @click="navSetting()">
					<el-image :src="require('@/pc/assets/login/icon-2.png')" class="img"></el-image>
					<div class="name">设置</div>
				</div>
				<div class="row item a" @click="tapLogout()">
					<el-image :src="require('@/pc/assets/login/icon-3.png')" class="img"></el-image>
					<div class="name">退出</div>
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	inject: ['initSuccess'],
	data() {
		return {
			activeName: 'first',
			userInfo: {},
			popUser: false,
			msg: {
				loading: true,
				finish: false,
				data: [],
				page: 0,
				pageSize: 10
			},
			total: {
				msg: '',
				notice: ''
			}
		};
	},
	computed: {
		...mapState(['token', 'tokenExpired']),
		pageShow() {
			return this.token && !this.tokenExpired;
		}
	},
	async created() {
		if (this.pageShow) {
			try {
				var { data } = await this.$service.post('user_info/detail');
				this.userInfo = data;
			} catch {}
		}
		this.initSuccess();
	},
	watch: {
		activeName: {
			handler() {
				this.msg = {
					loading: true,
					finish: false,
					data: [],
					page: 0,
					pageSize: 10
				};
				this.loadMsg();
			},
			immediate: true
		}
	},
	methods: {
		navMsg(index) {
			this.$set(this.msg.data[index], 'is_read', 1);
			let item = this.msg.data[index];
			this.$service.post('user_info/readMess', { mess_id: item.id });
			if (item.type == 2) {
				this.$router.push({
					path: '/user/home',
					query: { user_id: item.data.user_id }
				});
			} else if (item.type == 3 || item.type == 4 || item.type == 5 || item.type == 7 || item.type == 8 || item.type == 14) {
				this.$router.push({
					path: '/ask/detail',
					query: { id: item.data.question_id }
				});
			}
		},
		async loadMsg() {
			console.log('load');
			if (this.total.msg === '' || this.total.notice === '') {
				var { data } = await this.$service.post('user_info/pcMessList', { page: 1, pageSize: 10 });
				this.total.msg = data.total;
				var { data } = await this.$service.post('user_info/pcNoticeList', { page: 1, pageSize: 10 });
				this.total.notice = data.total;
			}
			this.msg.page++;
			var { data } = await this.$service.post(this.activeName == 'first' ? 'user_info/pcMessList' : 'user_info/pcNoticeList', {
				page: this.msg.page,
				pageSize: this.msg.pageSize
			});
			if (data.data.length < this.msg.pageSize) {
				this.msg.finish = true;
			}
			this.msg.total = data.total;
			this.msg.data = this.msg.data.concat(data.data);
			this.msg.loading = false;
		},
		navUserHome() {
			this.popUser = false;
			this.$router.push({
				path: '/user/home'
			});
		},
		navSetting() {
			this.popUser = false;
			this.$router.push({
				path: '/user/edit'
			});
		},
		tapLogout() {
			this.$store.dispatch('logout');
			window.location.reload();
		}
	}
};
</script>

<style scoped lang="less">
.user-box {
	flex-shrink: 0;
}
.msg-view {
	align-items: center;
	.img-view {
		width: 20px;
		height: 20px;
		position: relative;
		.msg {
			width: 100%;
			height: 100%;
		}
		.num {
			position: absolute;
			top: -12px;
			right: -80%;
			min-width: 28px;
			height: 28px;
			line-height: 28px;
			border-radius: 14px;
			background: #f30000;
			padding: 0 4px;
			color: #ffffff;
			font-size: 20px;
			transform: scale(0.5);
		}
	}
	.title {
		margin-left: 10px;
		color: #333333;
		font-size: 16px;
		font-weight: bold;
	}
}
.user-info {
	margin-left: 29px;
	min-width: 100px;
	.avatar {
		width: 38px;
		height: 38px;
		border-radius: 50%;
	}
	.nickname {
		margin-left: 10px;
		color: #999999;
		font-size: 16px;
	}
}
.user-info-popover {
	padding: 0 24px;
	box-sizing: border-box;
	.item {
		height: 54px;
		align-items: center;
		.img {
			width: 16px;
			height: 16px;
		}
		.name {
			margin-left: 10px;
			color: #666666;
			font-size: 14px;
			font-weight: bold;
		}
	}
}
/deep/ .el-tabs__nav-scroll {
	display: flex;
	align-items: center;
	justify-content: center;
}
/deep/ .el-tabs__nav-wrap::after {
	height: 1px;
	background-color: #f0f2f6;
}
/deep/ .el-tabs__active-bar {
	height: 0;
}
/deep/ .el-tabs__item {
	margin-top: 10px;
	font-size: 16px;
	color: #999999;
}
/deep/ .is-active {
	color: @green;
}
.msg-list {
	width: 100%;
	height: 360px;
	overflow: auto;
	.msg-item {
		padding: 14px;
		box-sizing: border-box;
		border-bottom: 1px solid #f0f2f6;
		.avatar {
			width: 44px;
			height: 44px;
			border-radius: 50%;
		}
		.avatar-box {
			position: relative;
			.avatar {
				width: 44px;
				height: 44px;
				border-radius: 50%;
			}
			.dot {
				position: absolute;
				right: 0;
				top: 0;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #eb0000;
			}
		}
		.msg-main {
			margin-left: 12px;
			flex: 1;
			overflow: hidden;
			.title {
				color: #333333;
				font-size: 14px;
				font-weight: bold;
			}
			.content {
				margin-top: 6px;
				color: #666666;
				font-size: 12px;
			}
			.time {
				margin-top: 10px;
				color: #999999;
				font-size: 12px;
			}
		}
	}
	.more-text {
		height: 55px;
		color: #999999;
		font-size: 14px;
	}
}
</style>

<template>
	<div class="header-container">
		<div class="row cc header">
			<div class="row cb header-box">
				<el-image class="app-logo a" :src="require('@/pc/assets/logo.png')" @click.stop="navHome()"></el-image>
				<div class="row middle">
					<el-popover placement="bottom" trigger="click" width="480" v-model="showPop">
						<div class="col search-box">
							<div class="row cb title-view">
								<div class="title">搜索历史</div>
								<div class="row cc del a" @click="deleteLog()">
									<span>清空历史</span>
									<i class="el-icon-delete"></i>
								</div>
							</div>
							<div class="row history">
								<div class="row cc h-item a" v-for="(item, index) in logList" :key="index" @click="tapKeywords(item.search_text)">
									<span>{{ item.search_text }}</span>
								</div>
							</div>
							<el-empty description="暂无搜索历史" :image-size="100" v-if="logList.length == 0"></el-empty>
							<div class="row cb title-view"><div class="title">热门问题</div></div>
							<div class="col hot-list">
								<div class="row hot-item a" v-for="(item, index) in hotQuestionList" :key="index" @click="navDetail(item.id)">
									<div class="row cc num">{{ index + 1 }}</div>
									<div class="title text-ellipsis">{{ item.title }}</div>
									<div class="row cc eye">
										<i class="el-icon-view"></i>
										<span>{{ item.view_num }}</span>
									</div>
								</div>
							</div>
							<el-empty description="暂无热门问题" :image-size="100" v-if="hotQuestionList.length == 0"></el-empty>
						</div>
						<div class="row cc input-view" slot="reference">
							<el-input placeholder="副主任护师晋升条件有哪些？" v-model="keywords" @keyup.enter.native="search()">
								<template slot="append">
									<el-button><i class="el-icon-search"></i></el-button>
								</template>
							</el-input>
						</div>
					</el-popover>
					<el-button class="ask" type="primary" round size="medium" @click="navAskAdd()">提问</el-button>
				</div>
				<div class="row cc right" v-show="loadingSuccess">
					<user-info></user-info>
					<login></login>
				</div>
				<div class="row cc right" v-show="!loadingSuccess">
					<el-skeleton style="width: 62px" animated>
						<template slot="template">
							<div><el-skeleton-item variant="p" style="height: 28px;" /></div>
						</template>
					</el-skeleton>
					<el-skeleton style="width:100px;margin-left: 29px;" animated>
						<template slot="template">
							<div class="row cc">
								<el-skeleton-item variant="p" style="width: 38px;height: 38px;border-radius: 50%;" />
								<el-skeleton-item variant="span" style="margin-left: 10px;width: 50px;height: 28px;" />
							</div>
						</template>
					</el-skeleton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import login from '@/pc/components/header/login.vue';
import userInfo from '@/pc/components/header/userInfo.vue';
export default {
	provide() {
		return {
			initSuccess: this.initSuccess
		};
	},
	data() {
		return {
			showPop: false,
			keywords: '',
			logList: [],
			hotQuestionList: [],
			childrenCount: 2,
			initSuccessCount: 0
		};
	},
	computed: {
		loadingSuccess(e) {
			return this.initSuccessCount === this.childrenCount;
		}
	},
	components: {
		login,
		userInfo
	},
	created() {
		this.keywords = this.$route.query.keywords || '';
		this.getLogList();
		this.getHotQList();
	},
	methods: {
		initSuccess() {
			this.initSuccessCount++;
		},
		search() {
			if (this.keywords.trim()) {
				this.showPop = false;
				if (this.$route.path == '/search') {
					this.$app.$emit('confirmSearch', this.keywords);
				} else {
					this.$router.push({
						path: '/search',
						query: { keywords: this.keywords }
					});
				}
			} else {
				this.$message({
					message: '请输入搜索关键词后查看~',
					type: 'warning'
				});
			}
		},
		tapKeywords(keywords) {
			this.keywords = keywords;
			this.search();
		},
		deleteLog() {
			this.$confirm('清空关键词搜索记录？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					try {
						await this.$service.post('home/delAllSearch', {});
						await this.getLogList();
					} catch {}
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				})
				.catch(() => {});
		},
		navDetail(id) {
			this.$router.push({
				path: '/ask/detail',
				query: { id }
			});
		},
		async getLogList() {
			let logList = await this.$service.post('home/searchList', {});
			this.logList = logList.data;
		},
		async getHotQList() {
			let hotQuestionList = await this.$service.post('question/hotQuestion', { page: 1, pageSize: 6 });
			this.hotQuestionList = hotQuestionList.data.data;
		},
		navAskAdd() {
			this.$router.push({
				path: '/ask/add'
			});
		},
		navHome() {
			this.$router.replace({
				path: '/'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './header.less';
</style>
